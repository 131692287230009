.form {
  width: auto;
  height: auto;
  /* background-color: var(--color-pale-gray); */
  padding: 3rem 2.5rem !important;
  border-radius:0 var(--border-radius-5xl);
  transition: 0.3s all ease-in-out;
  border: 1px solid var(--color-light);
  background-color: var(--color-dark-gray);
}


.form h3,
.pusblishingFormH2 {
  color: var(--color-dark) !important;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  margin-bottom: 1.5rem;
  text-align: start;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
}

.pusblishingFormH3 {
  color: var(--color-dark) !important;
  font-size: var(--font-size-md) !important;
  font-weight: var(--font-weight-bold) !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  margin-top: -1rem !important;
  text-transform: uppercase;
}

.form p {
  color: var(--color-dark) !important;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
}

.form:hover {
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.formGroup {
  display: flex !important;
  width: 100% !important;
  align-items: center;
  margin: 0.7rem 0;
  justify-content: center;
}

.formGroup .label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem;
  margin-top: 0.4rem;
  margin-right: 0.7rem;
  width: 15%;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  color: var(--color-dark);
  background-color: var(--color-white);
  border-radius: var(--border-radius-3xl);
  font-size: var(--font-size-lg);
  color: var(--color-dark-light);
}

.formGroup .formControl {
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--border-radius-3xl);
  border: none;
  padding: 0.7rem;
}

.formGroup .formControl {
  box-shadow: none !important;
  outline: none !important;
}

.formGroup .formControl:focus {
  outline: none;
}

.formGroup .formControl::placeholder {
  color: var(--color-dark);
  font-weight: var(--font-weight-bold);
}

.btnSubmit[type='submit'] {
  background-color: var(--color-primary);
  color: var(--color-white) !important;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-md) !important;
  border: none;
  border-radius: var(--border-radius-2xl);
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  width: 100% !important;
}

.btnSubmit[type='submit']:hover {
  background-color: var(--color-secondary);
}

.rowBtnForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  white-space: nowrap;
  margin-top: 1rem !important;
}

.formCheck {
  font-size: var(--font-size-sm);
  color: var(--color-dark);
  font-weight: var(--font-weight-bold);
  width: 90%;
  line-height: 0.9rem;
  text-align: start;
  text-decoration: none;
  transition: 0.2s all ease-in-out;
  margin: 0 auto;
  margin-top: 1.5rem;
}

@media screen and (min-width: 992px) {
  .formCheck {
    width: 80%;
  }
}

.formCheck:hover {
  color: var(--color-dark-light);
}

@media screen and (min-width: 992px) {
  .form {
    width: 98%;
    padding: 2rem 2.5rem;
  }
  .rowBtnForm {
    margin-top: 2rem !important;
  }
}

@media screen and (min-width: 1920px) {
  .form {
    padding: 2rem 2.5rem;
  }
}
