.navbar {
  padding: 0.5rem 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.dropdown-menu {
  border: none;
  border-radius: 0;
  background-color: #f8f9fa;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

@media screen and (min-width: 992px) {
  .navbar {
    padding: 1rem 0 !important;
  }
}
