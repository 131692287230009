.form {
  width: 100%;
  height: 100%;
  padding: 3rem 2.5rem !important;
  border-radius: var(--border-radius-5xl) 0;
  transition: 0.3s all ease-in-out;
  border: 1px solid var(--color-light);
  background-color: var(--color-dark-gray);
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form h3 {
  color: var(--color-dark) !important;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
}

.form p {
  color: var(--color-dark) !important;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.form:hover {
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.formGroup {
  display: flex !important;
  width: 100% !important;
  align-items: center;
  margin: 0.7rem 0;
  justify-content: center;
}

.formGroupCheck {
  margin: 1.5rem;
}

.formGroup .label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem;
  margin-top: 0.4rem;
  margin-right: 0.7rem;
  width: 15%;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  color: var(--color-dark);
  background-color: var(--color-white);
  border-radius: var(--border-radius-3xl);
  font-size: var(--font-size-lg);
  color: var(--color-dark-light);
}

.formGroup .formControl {
  width: 85%;
  background-color: var(--color-white);
  border-radius: var(--border-radius-3xl);
  border: none;
  padding: 0.7rem;
}

.formGroup .formControl {
  box-shadow: none !important;
  outline: none !important;
}

.formGroup .formControl:focus {
  outline: none;
}

.formGroup .formControl::placeholder {
  color: var(--color-dark);
  font-weight: var(--font-weight-bold);
}

.btnSubmit[type='submit'] {
  background-color: var(--color-primary);
  color: var(--color-white) !important;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-md) !important;
  border: none;
  border-radius: var(--border-radius-2xl);
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  width: 100% !important;
}

.btnSubmit[type='submit']:hover {
  background-color: var(--color-secondary);
}

.rowBtnForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100;
  margin: 0 auto;
  white-space: nowrap;
  margin-top: 1rem !important;
}

.formCheck {
  font-size: var(--font-size-sm);
  color: var(--color-dark);
  font-weight: var(--font-weight-bold);
  width: 100%;
  margin-top: -0.5rem;
  line-height: 0.9rem;
  text-align: start;
  text-decoration: none;
  transition: 0.2s all ease-in-out;
}

.formCheck:hover {
  color: var(--color-dark-light);
}

@media screen and (min-width: 992px) {
  .form {
    padding: 2rem 2.5rem;
  }
  .rowBtnForm {
    margin-top: 2rem !important;
  }
}

@media screen and (min-width: 1920px) {
  .form {
    width: 85%;
    padding: 2rem 2.5rem;
  }
}
