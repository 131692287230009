.stepContainer {
  margin: 3rem auto;
  width: 100%;
}

.stepCard {
  max-height: 400px;
  background-color: whitesmoke;
  border-radius: 50px;
  cursor: pointer;
  margin: 1.5rem 0;
  transition: 0.3s all ease-in-out;
}

.stepCard,
.stepCardBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: none !important;
  width: 100%;
  text-align: center;
}

.stepCardBody .stepsIconContainer {
  height: auto;
}

.stepCardBody .stepsIconContainer .stepsIcon {
  width: 100%;
  height: 100%;
  font-size: 6.5rem;
}

.stepCardIdContainer {
  margin: 1rem;
  height: 43px;
  width: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-primary);
  color: var(--color-white);
}

.stepCardIdContainer .stepCardId {
  font-size: var(--font-size-xl);
  font-weight: bold;
}

.stepCardBody .stepCardTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-black);
}

.stepCardBody .stepCardDesc {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  color: var(--color-black);
  height: 90px;
}

.publishForFreeContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 !important;
}

.publishForFreeLink {
  color: var(--color-white);
  background-color: var(--color-primary);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-medium);
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.3rem 2rem;
  border-radius: var(--border-radius-3xl);
}

.publishForFreeLink:hover {
  background-color: var(--color-primary-dark);
  color: var(--color-white);
}

@media screen and (min-width: 992px) {
  .stepContainer {
    width: 80%;
    margin: 8rem auto;
  }

  .stepCardBody {
    width: 100%;
    text-align: center;
  }
}
