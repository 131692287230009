.rowContainerSoldHome {
  position: relative;
  width: 100%;
  margin: 3rem auto !important;
}

.rowContainerSoldHomePrueba {
  max-width: 1235px;
  margin: 9rem auto !important;
}

.rowItems {
  display: flex;
  justify-content: center;
}

.colSoldHome {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0rem auto;
  width: 100%;
}

.img {
  margin: auto;
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 0 var(--border-radius-5xl);
}

@media screen and (min-width: 992px) {
  .rowContainerSoldHome {
    width: 100%;
    margin: 6rem auto !important;
  }

  .colSoldHome {
    margin: 0rem auto;
    display: flex;
    justify-content: flex-start;
    margin-left: 8%;
    align-items: center;
  }

  .img {
    width: 100% !important;
    height: 550px !important;
    object-fit: cover;
  }
}

@media screen and (min-width: 1920px) {
  .colSoldHome {
    margin-left: 15% !important;
  }
}
