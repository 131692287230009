.dropdownIconToggle {
  position: fixed !important;
  bottom: 0 !important;
  right: 0 !important;
  margin: 2.5rem;
  background-color: transparent !important;
  background: transparent !important;
  border: none !important;
  z-index: 999;
  display: block;
}

/* @media screen and (min-width: 992px) {
  .dropdownIconToggle {
    display: none;
  }
} */

.dropdownBtn {
  border: none !important;
}

.dropdownItem {
  animation: slideIn 0.3s ease-in-out;
  -webkit-animation: slideIn 0.3s ease-in-out;
  text-align: center !important;
  background-color: none !important;
  border: none !important;
  font-size: var(--font-size-4xl);
  color: var(--color-dark) !important;
  cursor: pointer;
  z-index: 999;

}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0rem);
    opacity: 1;
  }

  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

/* Gooey menu */
.msNavContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  display: block;
}

#ms-menu {
  display: none;
}

.bgChange {
  position: absolute;
  bottom: infinite;
  right: infinite;
  width: 1px;
  height: 1px;
  border-radius: 100% 0% 0% 0%;
  transition: all 0.25s ease;
}

.msNav .msMenuToggle {
  display: none;
}

.msNav .msMenuToggle:checked~.bgChange {
  width: 3000px;
  height: 3000px;
  transition: all 0.5s ease;
}

.msNav {
  position: absolute;
  bottom: 60px;
  right: 60px;
  list-style: none;
}

.msNav>.msMain {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.msNav>.msLi {
  position: absolute;
  bottom: 0rem;
  right: 0;
  transition: bottom 0.5s ease;
  z-index: 1;
}

.msNav .msMenuToggle:checked~.msLi a {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.msNav .msMenuToggle:checked~.msLi1 {
  bottom: 70px;
  transition: bottom 0.3s ease;
}

.msNav .msMenuToggle:checked~.msLi1 a span {
  opacity: 1;
  transition: opacity 0.5s ease 0.1s;
}

.msNav .msMenuToggle:checked~.msMain:after {
  animation: big-border-bottom-animation 0.5s ease 0.1s;
}

.msNav .msMenuToggle:checked~.msLi1:before {
  animation: border-top-animation 0.5s ease 0.2s;
}

.msNav .msMenuToggle:checked~.msLi1:after {
  animation: border-bottom-animation 0.5s ease 0.2s;
}

.msNav .msMenuToggle:checked~.msLi2 {
  bottom: 140px;
  transition: bottom 0.6s ease;
}

.msNav .msMenuToggle:checked~.msLi2 a span {
  opacity: 1;
  transition: opacity 0.6s ease 0.2s;
}

.msNav .msMenuToggle:checked~.msLi2:before {
  animation: border-top-animation 0.5s ease 0.2s;
}

.msNav .msMenuToggle:checked~.msLi2:after {
  animation: border-bottom-animation 0.5s ease 0.3s;
}

.msNav .msMenuToggle~.msLi:before {
  content: '';
  width: 45px;
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: auto;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}

.msNav .msMenuToggle~.msLi:before {
  content: '';
  width: 45px;
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: auto;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}

.msNav .msMenuToggle:checked~.msLi:before {
  content: '';
  width: 45px;
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: auto;
  border-left: 24px solid transparent;
  border-right: 24px solid transparent;
  border-top: 25px solid transparent;
  z-index: 11;
}

.msNav .msMenuToggle:checked~.msLi:after {
  content: '';
  width: 45px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-left: 24px solid transparent;
  border-right: 24px solid transparent;
  border-bottom: 25px solid transparent;
  transition: border-bottom 0.3s ease;
  z-index: 11;
}

.msNav .msMenuToggle:checked~.msMain:after {
  content: '';
  width: 60px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  margin: auto;
  border-left: 24px solid transparent;
  border-right: 24px solid transparent;
  border-bottom: 45px solid transparent;
  transition: border-bottom 0.3s ease;
  z-index: 11;
}

.msNav .msMenuToggle:checked~.msLi-last:after {
  content: '';
  width: 45px;
  display: block;
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  margin: auto;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
}

.msNav .msMenuToggle:checked~.msMain span {
  /* transform: rotate(-45deg); */
  transition: transform 0.35s ease;
}

.msNav .msMenuToggle~.msMain span {
  transition: transform 0.35s ease;
  opacity: 1;
}

.msNav>li a {
  font-size: 25px;
  background: var(--color-white);
  color: var(--color-primary);
  width: 60px;
  height: 60px;
  display: block;
  border-radius: 100%;
  position: relative;
  text-align: center;
  cursor: pointer;
}

.msNav {
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  z-index: 2;
}

.msNav>.msMain a,
.msNav>.msMain>a>label {
  font-size: 30px;
  color: var(--color-white);
  background-color: var(--color-primary);
  width: 60px;
  height: 60px;
  display: block;
  border-radius: 100%;
  position: relative;
  text-align: center;
  z-index: 19;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.msNav>li>a span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 30px;
  width: 30px;
  opacity: 0;
  z-index: 12;
  margin-bottom: 1.3rem;
}

@keyframes border-top-animation {
  0% {
    bottom: 0px;
  }

  50% {
    bottom: -14px;
  }

  100% {
    bottom: 0px;
  }
}

@keyframes border-bottom-animation {
  0% {
    top: 0px;
  }

  50% {
    top: -13px;
  }

  100% {
    top: 0px;
  }
}

@keyframes big-border-bottom-animation {
  0% {
    top: 0px;
  }

  50% {
    top: -30px;
  }

  100% {
    top: 0px;
  }
}