.col {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: blue;
  padding: 0.4rem 0rem;
  margin: 0 auto !important;
}

.card {
  background-color: var(--color-primary) !important;
  border-radius: var(--border-radius-5xl) !important;
  cursor: pointer;
  border: none;
  padding: 1rem 0.5rem !important;
  font-size: var(--font-size-sm);
  transition: all 0.3s ease-in-out !important;
  margin: 0.3rem;
  width: 100%;
}

.card:hover {
  background-color: var(--color-secondary) !important;
  scale: 1.1;
  z-index: 999;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.card:hover .moreInfo {
  background-color: var(--color-primary) !important;
}

.card:hover .moreInfo:hover {
  background-color: var(--color-primary-dark) !important;
}

.cardBody {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none;
}

.cardTitle {
  font-size: var(--font-size-2xl) !important;
  font-weight: var(--font-weight-bold) !important;
  color: var(--color-white) !important;
  text-align: center;
  width: 87%;
  margin: 0 auto;
}

.supplyContainer {
  background-color: var(--color-white) !important;
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  width: 80%;
  border: 1.5px solid var(--color-primary);
  border-radius: var(--border-radius-5xl);
  padding: 0.3rem 1rem;
  text-align: center !important;
  margin: 0.7rem auto;
}

.supply {
  color: var(--color-primary);
  font-size: var(--font-size-2xl) !important;
}

.brokersInfo {
  width: auto !important;
  font-size: var(--font-size-sm) !important;
  font-weight: bold;
}

.characteristics {
  color: var(--color-white);
  width: 85%;
  margin: 0 auto !important;
  padding: 1rem !important;
  font-size: 1.2rem !important;
  list-style-type: circle;
}

.characteristicsItem {
  line-height: 2.2rem !important;
}

.cardFooter {
  border: none !important;
  background-color: transparent !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.moreInfo {
  background-color: var(--color-secondary);
  color: var(--color-white) !important;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-md) !important;
  text-transform: uppercase;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius-2xl);
  cursor: pointer;
  width: 100% !important;
  text-decoration: none;
  white-space: nowrap;
}

@media screen and (min-width: 1920px) {
  .card {
    margin: 0rem;
  }
}
