.investingContainer {
  position: relative;
  width: 95%;
  margin: 3rem auto;
}

.investingCol {
  background-color: var(--color-light);
  border-radius: var(--border-radius-5xl);
  height: 28rem;
}

.investingColContent {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 1.5rem;
  position: relative;
}

.investingColAbsolute {
  display: hidden !important;
  position: absolute !important;
  right: 0rem !important;
  top: 0rem !important;
  width: 0rem !important;
  height: 0rem !important;
  border-radius: var(--border-radius-5xl) !important;
}

.investingColContent .investingColTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-black);
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.investingColTitle p {
}

.investingImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius-5xl);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.investingColContent p {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--color-dark);
  font-family: var(--font-family-body);
}

.consultationContainer {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.consultationContainer .consultation {
  color: var(--color-white);
  background-color: var(--color-primary);
  font-size: var(--font-size-lg);
  padding: 0.3rem 1rem;
  text-transform: uppercase;
  border-radius: var(--border-radius-3xl);
}

.consultationContainer .consultation:hover {
  background-color: var(--color-primary-dark);
  color: var(--color-white);
}

@media screen and (min-width: 992px) {
  .investingContainer {
    width: 80%;
    margin: 8rem auto;
  }

  .investingColAbsolute {
    position: absolute !important;
    right: -10rem !important;
    top: 20% !important;
    width: 50% !important;
    height: 70% !important;
    border-radius: var(--border-radius-5xl) !important;
    display: block !important;
  }

  .investingColContent {
    width: 65%;
    padding: 3rem;
  }
}

@media screen and (min-width: 1920px) {
  .consultationContainer {
    justify-content: start;
  }

  .consultationContainer .consultation {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-medium);
    text-decoration: none;
    padding: 0.3rem 2rem;
    text-align: center;
    white-space: nowrap;
  }

  .investingContainer {
    width: 55% !important;
  }
  .investingColAbsolute {
    top: 25.5% !important;
  }
}
