.customCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
  gap: 0.5em;

  /* background-color: Red; */
}

@media screen and (min-width: 1200px) {
  .customCol {
    width: 80% !important;
    margin: 1.5rem 0rem 0rem 3rem !important;
  }
}

.customRow {
  display: flex;
  justify-content: space-between;
  gap: 0.5em;
}

.mainContainer {
  width: 100%;
  align-items: flex-center;
  gap: 3rem;
}

.iconDivsContainer {
  width: 50%;
  align-items: flex-start;
  gap: 3rem;
}

/* STEPS HEADING */
.stepHeader {
  text-align: center;
  width: 50%;
}

/* END STEPS HEAING */

/* STEPS */
.stepsContainer {
  align-items: center;
  justify-content: start;
  gap: 3rem;
}

.stepsContainer p {
  font-size: 1.125rem;
}

/* END STEPS */

/* STEP ICON */
.stepIcon {
  max-width: 128px;
  min-width: 72px;
  max-height: 128px;
  min-height: 72px;

  color: var(--color-primary, #f57914);
}
/* END STEP ICON */

@media (width < 1200px) {
  .stepHeader {
    width: 100%;
  }
  .iconDivsContainer {
    width: 90%;
  }
  .mainContainer {
    align-items: center;
  }
}
