.layout {
  margin: 0.5rem 0;
  width: 100% !important;
  margin: 0 auto !important;
  height: auto !important;
}

@media screen and (min-width: 992px) {
  .layout {
    width: 100% !important;
    margin: 0 auto !important;
    position: relative;
  }
}
