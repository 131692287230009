.col {
  position: relative;
}

.extraIcon {
  color: var(--color-dark);
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

@media screen and (min-width: 992px) {
  .extraIcon {
    position: absolute;
    top: 25%;
    right: -18px;
    display: block;
    z-index: 1;
  }
}

.containerCol {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.card {
  margin: 1.5rem 0;
  background-color: white !important;
}

.card,
.cardBody {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  align-items: center !important;
  height: 15rem !important;
  border: none !important;
}

.cardIconContainer {
  background: var(--color-primary);
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 9rem !important;
  height: 9rem !important;
  border-radius: 50% !important;
  text-align: center !important;
  margin: 0px auto !important;
  padding: 3% !important;
  cursor: pointer !important;
  margin-bottom: 1rem !important;
}

.icon {
  color: var(--color-white);
  font-size: 5rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardText {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  color: var(--color-black);
  text-align: center;
  width: 80%;
  height: 50px;
  max-height: 50px;
  margin: 0 auto;
}

.spanText {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-normal);
  color: var(--color-black);
  text-align: center;
  width: 87%;
  margin: 0 auto;
}
