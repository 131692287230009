.rowContainer {
  width: 100%;
  margin: 2rem auto !important;
}

.rowItems {
  width: 100%;
}

.colForm {
  width: 100%;
  margin: 0.5rem;
}

.col {
  margin: 0 1rem;
}

@media screen and (min-width: 1920px) {
  .col {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 2%;
  }

  .rowContainer {
    width: 100%;
    margin: .5rem auto !important;
  }
}
