.customCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.customRow {
  display: flex;
  justify-content: space-between;
  gap: 0.5em;
}

.mainContainer {
  justify-content: center;
  align-items: center;
  margin: 5rem 0;
}

.submainContainer {
  background-color: var(--color-pale-gray, #f4f3f1);
  border-radius: var(--border-radius-5xl, 4rem);
  align-items: center;
  justify-content: flex-end;
  width: 1000px;
  padding: 2em;
  min-height: 543px;

  position: relative;
}

.image {
  border-radius: var(--border-radius-5xl, 4rem);
  object-fit: cover;
  width: 500px;
  height: 500px;

  position: absolute;
  left: -15%;

  transition: position 300ms linear, left 300ms linear, width 300ms linear,
    height 300ms linear;
}

@media (width <= 1400px) {
  .image {
    position: relative;
    left: 0;
    width: 50%;
  }
}
@media (width <= 1200px) {
  .submainContainer {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  .image {
    width: 90%;
  }
}
.form {
  /* position: fixed;

  top: 20%;
  left: calc(60vw + 2rem); */

  border-radius: var(--border-radius-5xl, 4rem);

  width: 580px;
  gap: 2rem;
  padding: 2em 2.5em;
}

/* HEADER */

.form__header {
  text-align: center;
}

.form__header > h2,
.form__header > h3 {
  font-weight: var(--font-weight-bold, 700);
}

.form__header > h2 {
  /* font-weight: var(--font-weight-extra-bold, 800); */
  font-size: var(--font-size-3xl, 4rem);
}

.form__header > h3 {
  font-size: var(--font-size-md, 1.4rem);
}

/* END HEADER */

/* BUTTON */

.form__btn {
  all: unset;

  font-weight: var(--font-weight-bold, 700);
  color: var(--color-white, white);
  background: var(--color-primary, #f57914);
  border-radius: var(--border-radius-3xl, 1.4rem);

  text-align: center;
  min-width: calc(100% / 3.8);
  padding: 0.5em 1.1em;

  filter: brightness(100%);
  transition: filter 300ms linear;
}

.form__btn:hover {
  filter: brightness(120%);
}

/* END BUTTON */

/* MAIN */

.form__inputs {
  margin: 1rem 0;
  width: 100%;
  gap: 1.2rem;
}

.form__inputs__name,
.form__inputs__tel,
.form__inputs__email {
  width: 70%;
}

.form__inputs > div > input {
  all: unset;

  background-color: var(--color-white, white);
  border-radius: var(--border-radius-3xl, 1.4rem);
  font-size: var(--font-size-md, 1rem);

  padding: 0.3em 1em;
  width: 100%;
}

.form__inputs > div > input::placeholder {
  font-weight: var(--font-weight-bold, 700);
  color: var(--color-dark, #252525);
}

/* END MAIN */

/* LABEL */
.form__inputs > label {
  margin-top: 1rem;
  font-size: var(--font-size-sm, 0.8rem);
  font-weight: var(--font-weight-bold, 700);
  max-width: 315px;
}

.form__inputs > label > input {
  align-self: flex-start;
  margin-top: 0.85%;
  border: none;
  width: 36px;
}
/* END LABEL */

/* ICON */

.formIcon {
  background: var(--color-white, white);
  color: var(--color-dark-light, #868686);
  border-radius: var(--border-radius-3xl, 1.4rem);

  width: 52px;
  height: 32px;

  padding: 0.3em 0.6em;
}

/* END ICON */
@media (width < 991px) {
  .form {
    width: 100%;
    max-width: 100%;
    height: 600px;
  }
}

@media (width < 400px) {
  .image {
    width: 100%;
    height: 250px;
  }
  .submainContainer {
    padding: 1.5em;
  }
  .form {
    padding: 2em 0em;
    height: max-content;
  }
}
