.aboutContainer {
  position: relative;
  width: 95%;
  margin: 3rem auto;
  position: relative;
}

.rowAbout {
  border-radius: var(--border-radius-5xl);
  position: relative;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftContent {
  background-color: var(--color-primary);
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  margin-right: 2rem;
  border-radius: var(--border-radius-2xl);
  width: 100%;
}

.leftContent ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: var(--color-white);
}

.leftContent ul li {
  font-weight: var(--font-weight-normal);
}

.leftContent ul li span {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  color: var(--color-white);
}

.rightContent {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align: start;
  padding: 2rem;
  margin: 0 auto;
  width: 100%;
  border-radius: var(--border-radius-2xl);
}

.rightContent .title {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  color: var(--color-black);
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.rightContent p {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--color-dark);
}

.rightContent .followUs {
  text-transform: uppercase;
}

.aboutCentredContent {
  display: none;
}

.aboutImg {
  position: relative;
}

@media screen and (min-width: 992px) {
  .aboutContainer {
    width: 85%;
  }
  .aboutCentredContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .leftContent {
    width: 63%;
  }
  .rightContent {
    width: 63%;
  }
}

@media screen and (min-width: 1920px) {
  .leftContent {
    width: 70%;
  }
  .rightContent {
    width: 80% !important;
  }
}

.socialMediaNavbarNav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 1rem;
  list-style: none;
  margin-top: 1.5rem;
  padding: 0;
  width: 100%;
}

.socialMediaNavLink {
  margin: 2rem;
  background-color: var(--color-dark);
  border-radius: 50%;
  padding: 0.2rem 0.65rem;
  text-align: center;
  font-size: var(--font-size-2xl);
  color: var(--color-white);
}

.socialMediaNavLink .icon {
  padding-bottom: 0.25rem;
  font-size: var(--font-size-2xl);
}

.socialMediaNavLink:hover {
  background-color: var(--color-light);
  color: var(--color-dark-light);
}
