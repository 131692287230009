.characteristicsContainer {
  margin-top: 2.5rem;
  padding: 0rem;
  height: 420px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: whitesmoke;
}

.characteristicsContainer::-webkit-scrollbar {
  width: 0.5rem;
}

/* fondo del scroll */
.characteristicsContainer::-webkit-scrollbar-track {
  background: var(--color-white);
}

/* scroll en si */

.characteristicsContainer::-webkit-scrollbar-thumb {
  background: var(--color-light);
  border-radius: 5px;
}

.characteristicsContainer h2 {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--color-dark);
  margin-bottom: 0;
  margin: 1rem 0;
}

.characteristicsContainer h6,
.characteristicsContainer p {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-normal);
  line-height: 1.2rem;
}

.characteristicsContainer h6 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  padding-bottom: 0.6rem;
}

.containerDescription {
  width: 95%;
  padding: 1rem;
}

.description {
  line-height: 27px !important;
  text-indent: 27px;
  border-radius: 20rem !important;
}

.description2 {
  line-height: 27px !important;
  border-radius: 20rem !important;
}

.tableDetailsContainer {
  padding: 2rem 0rem;
}

.buttonCollapse {
  margin: 2rem 0 !important;
  display: block !important;
  background-color: var(--color-primary) !important;
  width: 100% !important;
  border: none !important;
  outline: none !important;
  font-size: var(--font-size-sm) !important;
}

.buttonCollapse:hover {
  background-color: var(--color-primary-dark) !important;
}

@media screen and (min-width: 992px) {
  .characteristicsContainer {
    padding: 1rem 2rem;
  }
}
