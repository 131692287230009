.principalContainer {
  position: relative;
  margin: 6rem auto;
  width: 100%;
}

.rowPlanForm {
  position: relative;
  margin: 0 auto !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.principalImage {
  width: 100% !important;
  height: 300px !important;
  object-fit: cover !important;
  border-radius: 0px 100px !important;
  cursor: pointer;
}

.principalCol {
  padding: 0.5rem 1rem;
  position: relative;
}

.Container {
  color: white;
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.textContainer {
  width: 80%;
  margin: 0 0 0 3rem;
  text-shadow: 3px 3px 15px rgba(0,0,0,1);
}

.textContainer h2 {
  font-size: 2.25rem/* 36px */;
  line-height: 2.5rem/* 40px */;
  padding-bottom: 0.5rem/* 8px */;
  border-bottom-width: 2px;
}
.textContainer p {
  font-size: 1.25rem/* 20px */;
  line-height: 1.75rem/* 28px */;
}

@media screen and (min-width: 1024px) {
  .principalContainer {
    width: 100%;
    margin: 9rem auto;
  }

  .rowPlanForm {
    width: 80% !important;
  }

  .principalImage {
    width: 100% !important;
    height: 520px !important;
    object-fit: cover !important;
    border-radius: 0 150px !important;
  }
  .textContainer {
    width: 40%;
    margin: 0 0 0 3rem;
    text-shadow: 3px 3px 15px rgba(0,0,0,1);
  }
}

@media screen and (min-width: 1920px) {
  .rowPlanForm {
    width: 65% !important;
  }
}
