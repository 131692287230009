.number {
  background: var(--color-primary, #f57914);
  color: var(--color-white, #ffffff);
  font-size: var(--font-size-lg, 1.2rem);
  font-weight: var(--font-weight-bold, 700);

  min-width: 2.5rem;
  max-width: 2.5rem;
  height: 2.5rem;
  padding-top: 0.4em;

  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  user-select: none;
}
