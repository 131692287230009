.header {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: var(--color-white);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 0 1.5rem;
}

@media screen and (min-width: 1920px) {
  .header {
    padding: 0 10rem;
  }
}
