.modal {
  transition: 0.4s all ease;
  background-color: transparent !important;
}

.form {
  background-color: transparent !important;
}

.closeModal {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
}

.btnSubmit {
  width: 100% !important;
  white-space: nowrap;
}

.closeModal span:hover {
  color: var(--color-primary) !important;
}

.rowBtnForm {
  /* background-color: red; */
}
