.propertyDetailContainer {
  position: relative;
  margin: 5rem auto;
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--border-radius-xl);
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.detailContainer {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: var(--border-radius-xl);
  padding: 2rem;
  margin-top: 1.5rem;
  background-color: var(--color-white);
}

.detailsCard h2 {
  font-size: var(--font-size-sm) !important;
  margin: 0 !important;
}

.detailsCard .title {
  font-size: var(--font-size-sm) !important;
  padding: 0.3rem 0 !important;
  margin: 0 !important;
  color: rgb(156 163 175);
}

.detailsCard .publishedBy {
  font-size: var(--font-size-sm) !important;
  padding: 0.3rem 0 !important;
  margin: 0 !important;
  font-weight: bold;
}
.detailsCard .publishedBy span {
  font-weight: normal;
  color: rgb(156 163 175);
}

.row {
  margin: 0rem auto !important;
}

.col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: var(--color-white);
  border-radius: var(--border-radius-sm);
}

.propertyTypeInfo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  padding: 0.5rem !important;
  background-color: var(--color-white);
  -webkit-border-top-left-radius: 15px;
  -webkit-border-top-right-radius: 15px;
  -moz-border-radius-topleft: 15px;
  -moz-border-radius-topright: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: whitesmoke;
}

.propertyCharacteristics {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.propertyTypeInfo li {
  list-style: none;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  color: var(--color-dark);
  text-transform: uppercase;
  background-color: var(--bg-white);
}

.propertyTypeInfo li,
.propertyTypeInfo li a {
  text-decoration: none;
  background-color: var(--color-light);
  margin-right: 0.5rem;
  text-transform: capitalize;
  padding: 1.1rem 0.3rem;
  font-size: var(--font-size-md);
  background-color: var(--bg-white);
}

.propertyTypeInfo li a {
  color: var(--color-black);
  font-weight: var(--font-weight-bold);
}

.propertyTypeInfo li {
  color: var(--color-dark);
  font-weight: var(--font-weight-medium);
}

.deptoDetailsShare {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
}

.deptoDetailsShare .spanClipboard,
.printLink {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: row !important;
  width: 100% !important;
  cursor: pointer !important;
  background-color: whitesmoke !important;
  padding: 0.5rem 1rem !important;
  border-radius: 3px !important;
  font-size: var(--font-size-md) !important;
  white-space: nowrap !important;
  transition: 0.2s all ease-in-out !important;
  border: none !important;
  color: #4b5563 !important;
}

.printLink:active {
  background-color: var(--color-primary);
  color: var(--color-white) !important;
}

.deptoDetailsShare .spanClipboard:hover,
.printLink:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 1) !important;
}

.deptoDetailsShare .spanClipboardCopied {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
  background-color: whitesmoke;
  color: green;
  padding: 0.5rem 1rem !important;
  border-radius: 10px;
  font-size: var(--font-size-md);
  white-space: nowrap;
}

.shareLink,
.printLink {
  text-decoration: none;
  color: var(--color-dark);
  font-size: var(--font-size-md);
  transition: 0.3 all ease-in-out;
}

.detailContainer h2 {
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-extra-bold);
  color: var(--color-dark);
  margin-bottom: 0;
  margin: 1rem 0;
}

.detailContainer h4 {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--color-dark);
}

.pricesContainer {
  margin: 0.7rem 0;
}

.pricesContainer span {
  font-size: var(--font-size-sm);
  margin: 0 !important;
  padding: 0 !important;
}

.pricesContainer p {
  margin: 0 !important;
  padding: 0 !important;
}

.detailContainer .ufPrice,
.detailContainer .clpPrice {
  margin: 0 !important;
  padding: 0 !important;
  text-transform: uppercase;
}

.detailContainer .ufPrice {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-medium);
}

.detailContainer .clpPrice {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: rgb(156 163 175);
}

.deptoPropsContainer {
  display: flex;
  flex-direction: column;
}

.deptoPropsContainer span {
  margin: 0.2rem 0;
  font-size: var(--font-size-sm);
  color: rgb(156 163 175);
}

.deptoPropsContainer span strong {
  margin-right: 0.5rem;
  color: var(--color-dark);
}

.quoteBtn {
  background-color: var(--color-primary);
  color: var(--color-white) !important;
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-xl) !important;
  border: none;
  border-radius: var(--border-radius-2xl);
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  width: 90% !important;
  margin: 1.5rem 0 !important;
  padding: 0.3rem;
  text-align: center;
}

.quoteBtn:hover {
  background-color: var(--color-primary-dark);
}

@media screen and (min-width: 992px) {
  .propertyDetailContainer {
    width: 80% !important;
  }
}

@media screen and (min-width: 1400px) {
  .propertyDetailContainer {
    margin: 7.5rem auto;
  }
}

.propertyTypeInfo .divider {
  color: rgba(0, 0, 0, 0.2);
}
