@tailwind base;
/* @tailwind components; */
@tailwind utilities;

:root {
  /* Color Palette */
  --color-primary: #f57914;
  --color-secondary: #4e2772;
  --color-terciary: #39b54a;
  --color-quinary: #1ea498;
  --color-quaternary: #ed4f44;
  --color-senary: #f1bd21;
  --color-septenary: #009245;
  --color-octonary: #f09425;
  --color-nonary: #95b8f6;
  --color-black: #000000;
  --color-dark: #252525;
  --color-dark-light: #868686;
  --color-dark-gray: #d4d3d3;
  --color-pale-gray: #e1e6e7;
  --color-pale-gray-dark: #adadad;
  --color-white: #ffffff;
  --color-light: #f5f5f5;
  --color-bg-publishing-form: hsl(260 5% 35% /0.2);
  --color-primary-dark: #e77212;

  /* Font size */
  --font-size-xs: 0.6rem;
  --font-size-sm: 0.8rem;
  --font-size-md: 1rem;
  --font-size-base: 1.1rem;
  --font-size-lg: 1.2rem;
  --font-size-xl: 1.4rem;
  --font-size-xxl: 1.6rem;
  --font-size-2xl: 1.8rem;
  --font-size-3xl: 2rem;
  --font-size-4xl: 2.2rem;
  --font-size-5xl: 2.4rem;

  /* Font weight */
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;

  /* Font family */
  --font-family-secondary: 'Roboto', sans-serif;

  /* Border radius */
  --border-radius-sm: 0.2rem;
  --border-radius-md: 0.4rem;
  --border-radius-lg: 0.6rem;
  --border-radius-xl: 0.8rem;
  --border-radius-xxl: 1rem;
  --border-radius-2xl: 1.2rem;
  --border-radius-3xl: 1.4rem;
  --border-radius-5xl: 4rem;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  background-color: white !important;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../src/assets/fonts/Poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppin-semibold';
  src: url('../../src/assets/fonts/Poppins/Poppins-SemiBold.ttf');
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Poppins';
}

html,
body {
  background: var(--text-white);
  color: var(--text-dark);
}

a,
ul,
li,
span,
button {
  color: inherit;
  text-decoration: none !important;
  font-family: 'Poppins' !important;
}

h1,
h2,
h3,
h4 {
  font-family: 'Poppin-semibold' !important;
}

p {
  margin: 0 !important;
  padding: 0 !important;
}

button {
  outline: none !important;
}

button:focus {
  outline: none !important;
}

/* MODAL CONTACT FORM - ADMINISTRACIÓN DE ARRIENDO */

.modal-content {
  border-radius: var(--border-radius-5xl) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  margin: 0 auto !important;
  border: none !important;
}
.btn-close {
  display: none;
}

.modal-header {
  display: none !important;
}

.modal-content > div span {
  display: none !important;
}

/* TABS - DETALLE PROPIEDAD */
.nav-tabs {
  background-color: transparent !important;
  border-radius: var(--border-radius-5xl) !important;
  border: none !important;
}

.nav-tabs .nav-link {
  border-radius: var(--border-radius-5xl) !important;
  color: var(--color-dark) !important;
  border: none !important;
  margin-right: 2rem !important;
}

.nav-tabs .nav-link:hover {
  background-color: var(--color-primary-dark) !important;
  color: var(--color-white) !important;
}

.nav-tabs .active {
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
}

.image-gallery-thumbnail-image {
  border-radius: var(--border-radius-xl) !important;
  /* width: 100% !important; */
  width: 98.5% !important;

  max-width: 98.5%;
  height: 68px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  position: relative;
}

.image-gallery-image {
  width: 98.5% !important;
  height: 250px !important;
}

@media screen and (min-width: 992px) {
  .image-gallery-content {
    height: 500px !important;
    max-height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .image-gallery-image {
    height: auto !important;
  }

  .image-gallery-slide-wrapper {
    height: 500px !important;
    max-height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .image-gallery-swipe {
    height: 500px !important;
    max-height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .image-gallery-slides {
    height: 500px !important;
    max-height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.col-sm-4 {
  width: 100% !important;
}

/* MAPBOX */
.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none !important;
}
