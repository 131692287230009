.footerContainer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  width: auto;
  background-color: rgb(56, 56, 56);
  padding: 1.5rem;
  z-index: 1;
}

.textContainer {
  color: white;
  text-align: center;
}
.textContainer a {
  color: rgb(216, 140, 0);
  text-align: center;
}
.textContainer a:hover {
  color: orange;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .footerContainer {
    padding: 3rem 6rem;
  }
}

.aboutFooterContainer,
.addressFooterContainer,
.navigationFooterContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.aboutFooterContainer .aboutFooterTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--color-white);
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.aboutFooterContainer .aboutFooterParagraph {
  font-size: var(--font-size-md);
  color: var(--color-white);
}

@media screen and (min-width: 992px) {
  .aboutFooterContainer,
  .addressFooterContainer,
  .navigationFooterContainer {
    width: 82%;
  }
}

.addressFooterContainer .addressFooterTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  color: var(--color-white);
  text-transform: capitalize;
}

.addressFooterInfoSection {
  margin: 0.5rem 0;
}

.addressFooterContainer .addressFooterParagraph {
  color: var(--color-white);
  line-height: 0.95rem;
}

.navigationFooterContainer .navigationFooterInfoSection {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  color: var(--color-white);
  text-transform: capitalize;
  line-height: 2.5rem;
  width: 100%;
  text-align: center;
  padding-left: 0rem;
  margin-top: 0.5rem;
}

@media screen and (min-width: 992px) {
  .navigationFooterContainer .navigationFooterInfoSection {
    padding-left: 5rem;
    text-align: start;
  }
}

.navLinkFooter {
  text-decoration: none !important;
  color: var(--color-white) !important;
}

.navLinkFooter:hover {
  color: var(--color-light) !important;
}

.socialMediaTopInfo {
  margin-bottom: 2.5rem !important;
}

.socialMediaTopInfo h3 {
  font-size: var(--font-size-md);
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  text-align: center;
  color: var(--color-white);
}

.socialMediaNavbarNav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
}
