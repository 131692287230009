.customCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.customRow {
  display: flex;
  justify-content: space-between;
  gap: 0.5em;
}

.imgAndForm {
  gap: 5%;
}

/* IMG-HEADER */
.imgContainer {
  align-self: center;
  justify-content: center;
  width: 100%;
}

.imgHeader {
  object-fit: cover;
  border-radius:0 var(--border-radius-5xl, 4rem);
}
/* END IMG-HEADER */

@media (width < 991px) {
  .imgAndForm {
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: center;
  }

  .imgContainer {
    width: 100%;
  }


}
