.customCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.customRow {
  
  gap: 0.5em;
}

/* .cardContainer {
  border-radius: var(--border-radius-5xl, 4rem);
  background: var(--color-pale-gray, #f4f3f1);
  border: 1px solid black;
  min-height: 380px;
  gap: 0;
  width: 55%;
  overflow: hidden;
  user-select: none;
  transition: transform 250ms linear;
  margin: 0 1.5em ;
  margin-bottom: 0.5em;
} */

/* @media only screen and (min-width:1367px){
  .cardContainer {
    width: 85%;
    margin: 0 0.2em ;

  }
} */

/* @media only screen and (min-width:370px){
  .cardContainer {
    width: 85%;
  }
} */

.cardContainer:is(:hover, :focus) {
  transform: scale(1.1);
}

.cardContainer:has(.cardImgContainer) .cardImgContainer {
  background: var(--color-primary, #f57914);
  width: 100%;
  padding: 2rem;
  transition: background 250ms linear;
}

.cardContainer:is(:hover, :focus):has(.cardImgContainer) .cardImgContainer {
  background: var(--color-secondary, #4e2772);
}

.card__img {
  width: 128px;
  min-width: 32px;
  height: 128px;
  min-height: 32px;
  color: var(--color-white, #fff);
}

.cardTextContainer {
  padding: 1.2em 2em;
  text-align: center;
  min-height: 155px;
}

.card__h2 {
  font-size: var(--font-size-lg, 1.2rem);
  font-weight: var(--font-weight-bold, 700);
}

.card__p {
  font-size: var(--font-size-sm, 0.8rem);
}

@media (width <= 1600px) {
  .card__h2 {
    font-size: var(--font-size-md, 1rem);
    font-weight: var(--font-weight-bold, 700);
  }

  .card__p {
    font-size: var(--font-size-sm, 0.6rem);
  }
  .cardTextContainer {
    padding: 1em 1em;
    text-align: center;
  }
  .card__img {
    width: 72px;
    height: 72px;
  }
}

@media (width <= 1350px) {
  .cardTextContainer {
    padding: 1.2em 2em;
    text-align: center;
    max-height: 155px;
    max-width: 300px;
  }

  .card__h2 {
    font-size: var(--font-size-lg, 1.2rem);
    font-weight: var(--font-weight-bold, 700);
  }

  .card__p {
    font-size: var(--font-size-sm, 0.8rem);
  }
}
