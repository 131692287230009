.customCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.customRow {
  display: flex;
  justify-content: space-between;
  gap: 0.5em;
}
.steps__span {
  font-size: var(--font-size-lg, 1.2rem);
  font-weight: var(--font-weight-bold, 700);
}

.steps__p,
.steps__span {
  text-align: center;
}
