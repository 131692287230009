.containerSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-dark);
  width: 100%;
  margin: 2rem auto !important;
  text-align: center;
  position: relative;
}

.containerSection h2{
  color: #31254c !important;
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
}

.h2 {
  color: #31254c;
  /* font-size: var(--font-size-2xl); */
  font-weight: 700;
  text-align: center;
  width: 100% !important;
  padding-top: 1rem !important;
  margin: 0rem !important;
  padding: 0 !important;
}

.h2_3xl {
  /* font-size: var(--font-size-2xl) !important; */
  font-weight: var(--font-weight-bold) !important;
}

.text-center {
  text-align: center !important;
}

@media screen and (min-width: 992px) {
  .containerSection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
  }


  .containerSection h2{
    font-size: 3rem;
    line-height: 3.75rem;
  }

  .h2_3xl {
    /* font-size: 4.5rem !important; */
  }
}
