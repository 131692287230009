.customCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.customRow {
  display: flex;
  justify-content: space-between;
  gap: 0.5em;
}

.sectionHeroContainer {
  margin-top: 6.5rem;
}

.heroH1 {
  font-weight: var(--font-weight-bold, 700);
}
.heroImage {
  border-radius: 60px 0px 60px 0px;
  object-fit: cover;
}
.servicesContainer {
  width: 50%;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}
.headerContainer {
  width: 50%;
  text-align: center;
}

@media (width <= 1200px) {
  .sectionHeroContainer {
    flex-wrap: wrap-reverse;
    gap: 3rem;
  }
  .headerContainer,
  .servicesContainer {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .sectionHeroContainer {
    margin-top: 11rem;
  }
}
