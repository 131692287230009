.toastContainer {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  z-index: 999999 !important;
  padding: 10px !important;
  overflow: hidden !important;
  width: 320px !important;
  box-sizing: border-box !important;
}
